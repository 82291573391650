/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


//Returns whether this is the EXTERNAL or INTERNAL version of the site
export const version = () => {
	return window.env['app-version'];
}

export const isExternal = () => {
	return version() === 'EXTERNAL';
}

export const isInternal = () => {
	return version() === 'INTERNAL';
}

export const currentLang = () => {
	return document?.documentElement?.lang ?? 'en'; // en/fr
}

export const isEnglish = () => {
	return currentLang() === 'en';
}

export const isFrench = () => {
	return currentLang() === 'fr';
}

export const currentLangCamelcase = () => {
	return currentLang() === 'en' ? 'En' : 'Fr'; 
}

/*
	Add a zero-width space (won't show up on display) after a specified character

	Browsers automatically know to break up words onto new lines, but in some cases you'll get something like
	"Winterland/Marystown" which it will treat as a single word and not break which can cause issues on small screens
	This way we can tell it to treat '/' as a breakable character
*/
export const addLineBreakAfterChar = (text, char) => {
	if (!text) return '';
    const zeroWidthSpace = '\u200B';
    const regex = new RegExp(`${char}`, 'g');
    return text.replace(regex, char + zeroWidthSpace);
}

// I just hate having to write this manually all the time
export const domId = (domId) => {
	return document.getElementById(domId);
}

// Sort an array alphabetically by one of its props, can be reverse sorted
export const sortArrayAlphabetically = (array, prop, reverse) => {
	array?.sort((a, b) => {
		if (a[prop] < b[prop]) {
		  return reverse ? 1: -1;
		}
		if (a[prop] > b[prop]) {
		  return reverse? -1: 1;
		}
		return 0;
	  });
	return array;
}

export const isEmpty = (obj) => {
	if(!obj)
		return true;
	return Object.keys(obj).length === 0;
}

//Convert UTC time string (e.g. '2024-05-09T13:30:05.766Z') to local time and yyyy-mm-dd hh:mm format (e.g. 2024-05-09 13:30)
export const convertUtcTimeToLocalStandardFormat = (utcTimeString) => { 
	const utcDate = new Date(utcTimeString);
	if (isNaN(utcDate.getTime())) {
		console.error('Error converting utc time string', utcTimeString)
		return '';
	}

	const year = utcDate.getFullYear();
	const month = String(utcDate.getMonth() + 1).padStart(2, '0');
	const day = String(utcDate.getDate()).padStart(2, '0');
	const hours = String(utcDate.getHours()).padStart(2, '0');
	const minutes = String(utcDate.getMinutes()).padStart(2, '0');

	const localDateTimeString = `${year}-${month}-${day} ${hours}:${minutes}`;

	return localDateTimeString;
}