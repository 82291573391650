/******** THIS IS A COPIED FILE FROM THE SHARED FOLDER, DO NOT MODIFY/SAVE ********/


export const NON_PRINT_CHAR = '\u200C';
const ASC_SORT_VALUE = '<span></span>';
const DESC_SORT_VALUE = `<span>${NON_PRINT_CHAR}</span>`; //Switching these values again.
														  //This still confuses my brain a little
														  //I tested a few times and seems like 
														  //ASC sort: <span></span> is put last by WETTable
														  //DESC sort: <span>${NON_PRINT_CHAR}</span> is put last by WETTable

/* 
    WETTable custom sort so that blank values will always be last
    -run this after table has been initialized e.g. inside of a table.on('init.dt')
*/

let isManualSort = false;

export const showBlanksLast = (tableId, colNum) => {
	const table = window.$('#' + tableId);

	const dataTable = table.DataTable(); //Retrieve the table first before sort manipulation 
										 //Table behaves weirdly in unexpected ways if called
										 //after sorting event was triggered

	table.on('order.dt', (e, settings) => {
		if (isManualSort) return;

		const order = settings.aaSorting;
		const sortedColumnIndex = order[0][0];
		const sortedDirection = order[0][1]; // 'asc' or 'desc'

		if (sortedColumnIndex === colNum) {
			const replaceValue = sortedDirection === 'asc' ? ASC_SORT_VALUE : DESC_SORT_VALUE;

			// Manipulate data in the sorted column
			dataTable.rows().every(function () {
				const data = this.data();
				if ( data[colNum] === ASC_SORT_VALUE || data[colNum] === DESC_SORT_VALUE ) {
					dataTable.cell(this.index(), colNum).data(replaceValue);
				}
			});

            // // Workaround for bug DORB-483: call the sort twice
			// isManualSort = true; // Set flag to prevent endless loop
			// table.DataTable().order([colNum, sortedDirection]).draw(); // Reapply the same sort
			// isManualSort = false; // Reset flag
		}
	});
};
